var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',[_vm._v("內容管理中心")]),_c('b-breadcrumb-item',{attrs:{"to":{ name: 'CollectionList' },"active":""}},[_vm._v(" 資料集列表 ")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-8 mb-2 font-weight-bold"},[_vm._v("資料集列表")]),_c('b-input-group',{staticClass:"col-12 col-xl-4 mb-2"},[_c('b-form-input',{attrs:{"placeholder":"搜尋"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":_vm.fetchCollections}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1),_c('div',{staticClass:"d-flex mb-4 mb-xl-2 justify-content-between"},[_c('div',{staticClass:"\n          d-flex\n          flex-column flex-xl-row\n          align-items-start align-items-xl-center\n        "},[(_vm.checkPermission([_vm.consts.COLLECTION_CREATE]))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0",attrs:{"variant":"primary","to":{ name: 'CollectionCreate' }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增資料表")]):_vm._e()],1)]),_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.collections,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(is_enabled)",fn:function(data){return [(data.item.is_enabled == true)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" 啟用 ")]):_vm._e(),(data.item.is_enabled == false)?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" 停用 ")]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [(_vm.checkPermission([_vm.consts.COLLECTION_EDIT]))?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"inverse-warning","to":{
                    name: 'CollectionEdit',
                    params: { id: data.item.id },
                  }}},[_c('span',{staticClass:"mdi mdi-lead-pencil"}),_vm._v(" 編輯 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.COLLECTION_ITEM_MANAGE]))?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"inverse-success","to":{
                    name: 'CollectionItemList',
                    params: { id: data.item.id },
                  }}},[_c('span',{staticClass:"mdi mdi-lead-pencil"}),_vm._v(" 項目管理 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.COLLECTION_DELETE]))?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.deleteCollection(data.item)}}},[_c('span',{staticClass:"mdi mdi-delete"}),_vm._v(" 刪除 ")]):_vm._e()]}}])})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"center"},on:{"change":_vm.fetchCollections},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }